import {
    GLOBAL_WIDTH_SIZE_DESKTOP,
    GLOBAL_WIDTH_SIZE_MOBILE,
    MEDIA_CONIDTION_SIZES_LAPTOP_ONLY,
    MEDIA_CONIDTION_SIZES_LARGE_SCREEN,
} from '~/modules/images/config/sizes-constant';

/**
 * This function returns an object that contains the sizes of the images for the different screen sizes.
 *
 * @param imageContainerWidth The width of the image container in pixels.
 *
 * @returns The sizes of the images for the different screen sizes.
 * @example
 * getImagesSizes({desktop: 400, mobile: 100})
 * // => "(min-width: 1921px) 400px, ((min-width: 1024px) and (max-width: 1920px)) 20vw, 10vw"
 */

export const getImagesSizes = (
    imageContainerWidth: number | Record<'desktop' | 'mobile', number>
): string => {
    const desktopContainer = GLOBAL_WIDTH_SIZE_DESKTOP;
    const mobileContainer = GLOBAL_WIDTH_SIZE_MOBILE;

    // If the imageContainerWidth is an object, it means that the image container width is different for mobile and desktop.
    if (typeof imageContainerWidth === 'object') {
        const { desktop: desktopWidth, mobile: mobileWidth } =
            imageContainerWidth;
        return setImageSizes({
            largeScreen: desktopWidth,
            laptop: `${getImageContainerWidthPercent(
                desktopWidth || desktopContainer,
                desktopContainer
            )}vw`,
            all: `${getImageContainerWidthPercent(
                mobileWidth || mobileContainer,
                mobileContainer
            )}vw`,
        });
    }

    return setImageSizes({
        largeScreen: imageContainerWidth,
        laptop: `${getImageContainerWidthPercent(
            imageContainerWidth,
            desktopContainer
        )}vw`,
        all: `${getImageContainerWidthPercent(
            mobileContainer,
            mobileContainer
        )}vw`,
    });
};

interface ImageSizesScreen {
    largeScreen?: number;
    laptop?: string;
    all: string;
}

/**
 * Set image sizes for different screen sizes
 * @param largeScreen Pixel values
 * @param laptop vw values
 * @param mobile vw values
 * @returns
 */
const setImageSizes = ({ largeScreen, laptop, all }: ImageSizesScreen) => {
    const largeScreenSize =
        largeScreen &&
        `${MEDIA_CONIDTION_SIZES_LARGE_SCREEN} ${largeScreen}px, `;
    const laptopSize =
        laptop && `${MEDIA_CONIDTION_SIZES_LAPTOP_ONLY} ${laptop}, `;
    const allSize = `${all}`;
    return `${largeScreenSize || ''}${laptopSize || ''}${allSize}`;
};

/**
 * Return a percentage value based on the screen width and the image container width.
 */
const getImageContainerWidthPercent = (
    imageContainerWidth: number,
    screenWidth: number
): number => {
    return Math.round((imageContainerWidth / screenWidth) * 100);
};
